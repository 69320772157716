<template>
  <div class="item">
    <h4 class="item-title"
        v-if="name !== '' && name !== undefined"
    >{{ name }}</h4>
    <div style="position:relative; display:inline-block" class="unselect"
         :class="{'full-width': name === '' || name === undefined}">
      <div :style="dropdownSelectWrapper"
           :class="{'full-width': name === '' || name === undefined}"
           @click.stop.prevent="openList">
        <span class="drop-down-select-item" :class="value !== '' ? 'sub' : 'sub4'">
          {{ (inputValue === '') ? (placeholder || '') : valueLabel }}
        </span>
        <img class="drop-down-arrow-icon svg-sub2" src="/static/icon/fi_chevron-down.svg" style="width:24px;height:24px" v-if="!isOpen"/>
        <img class="drop-down-arrow-icon svg-sub2" src="/static/icon/fi_chevron-up.svg" style="width:24px;height:24px" v-else/>
      </div>
      <div v-if="isOpen"
           class="drop-down-list-outsider"
           @click="isOpen=false">
      </div>
      <ul v-if="isOpen" class="drop-down-list" :style="listStyle">
        <li v-for="(opt,idx) in options" :key="'sort-list'+idx"
            :style="dropdownItem"
            class="selectable-list-item sub"
            @click="clickTargetItem(opt)">{{ opt.label }}</li>
      </ul>
    </div>
    <div v-if="isOpen" class="drop-down-overlay" @click="isOpen=false"></div>
    <!-- <select v-model="value">
      <option v-for="item in options" :value="item">{{item}}</option>
    </select> -->
    <div v-if="valueLabel === '기타'" class="margin-top-8">
      <lp-text @update:value="etcInputValue" placeholder="기타 사유를 입력해주세요" ></lp-text>
    </div>
  </div>
</template>

<script>
import LpText from './component/LpText.vue';
  export default {
  components: { LpText },
    props: {
      name: {
        type: String,
        required: false
      },
      customWrapperStyle: {
        type: Object
      },
      customItemStyle: {
        type: Object
      },
      value: {
        type: [String, Number]
      },

      elt: {
        required: false
      },

      options: {
        type: Array,
        required: true
      },

      placeholder: {
        type: String,
        required: false
      },

      height: {
        type: Number,
        default: 46
      }
    },

    data() {
      return {
        isOpen: false,
        inputValue: this.value,
        oldValue: this.inputValue,
        selVal: '',
        etcValue: '',
      }
    },

    computed: {
      dropdownItem() {
        let deco = {
          // border: '1px solid #e3e3e3',
          padding: '12px 16px',
          fontSize: '15px',
          height: '46px',
          fontWeight: 400
        }
        if(this.customItemStyle) {
          deco = this.customItemStyle;
        }
        return deco;
      },
      dropdownSelectWrapper() {
        let deco = {
          border: '1px solid #e3e3e3',
          borderRadius: '8px',
          padding: '12px 16px',
          fontSize: '15px',
          height: '46px',
          fontWeight: 300
        }
        if(this.customWrapperStyle) {
          deco = this.customWrapperStyle;
        }
        return deco;
      },
      valueLabel() {
        let found = this.options.find(opt => {
          return opt.value === this.value;
        });
        if (found)
          return found.label;
        else
          return undefined;
      },

      listStyle() {
        return {
          top: `${this.height}px`
        }
      }
    },

    created() {
      this.inputValue = this.value;
    },
    watch: {
      inputValue(n) {
        this.$emit('update:value', n);
      },
      value(n) {
        this.inputValue = n;
      },
      ectValue(n) {
        this.$emit('update:etc', n)
      }
    },
    methods: {
      openList() {
        if (!this.isOpen) {
          this.isOpen = true;
          //console.log('open list');
          this.onInputStart(this.inputValue);
        } else {
          this.isOpen = false;
        }
      },
      etcInputValue(n) {
        this.etcValue = n;
        this.$emit('etc', n);
    },
      clickTargetItem(item) {
        this.inputValue = item.value;
        if (this.etcValue !== '') {
          this.$emit('update:etc', this.etcValue)
        }
        // setLog가 val 변경 전에 실행되는 문제가 있어서 추가함.
        this.$emit('update:val', this.inputValue);
        this.$emit('selected', item);
        this.onInputDone(item.val);
        this.isOpen = false;
      },

      onInputStart(val) {
        this.oldValue = val;
      },

      onInputDone() {
        // this.value = val;
        if (this.oldValue !== this.inputValue) {
          this.$root.$emit('setLog');
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .item
    font-size .8rem
    position relative

  .full-width
    width 100%

  .drop-down-select-wrapper
    display flex
    align-items center
    width 100%
    background #fff
    justify-content space-between
    font-size .8rem
    height 32px
    cursor pointer
    border 1px solid #EEEEEE

  .drop-down-select-item
    display flex
    align-items center
    cursor pointer
    font-weight 400

  .drop-down-arrow-icon
    cursor pointer
    position absolute
    right 16px
    top 13px
  .drop-down-list
    position absolute
    background-color #fff
    z-index 12
    /*border 1px solid #eaeaea*/
    top 20px
    left 0
    width 100%
    cursor pointer
    /*border-top none*/
    list-style-type none
    padding 0px
    filter drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1))
    border-radius 8px
    max-height 320px
    overflow-y auto

  .selectable-list-item:hover
    background-color $gray4

  .drop-down-item
    padding 12px 16px
    font-size 15px
    background-color #fff
    color #333333
    white-space pre-line

  .drop-down-overlay
    position fixed
    z-index 11
    left 0
    top 0
    bottom 0
    right 0
</style>
